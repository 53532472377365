import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { Api, getUID, HTTP_STATUS } from '../../../shared';
import { Cache } from '../../../cache';

const NAMESPACE = 'balance';

const initialState = {
  totalBalance: {
    btc: {
      value: 0.0,
      unit: 'BTC',
    },
    usd: {
      value: 0.0,
      unit: 'USD',
    },
  },
  balances: undefined,
  getBalanceStatus: HTTP_STATUS.IDLE,
  getBalanceMessage: '',
};

export const getBalances = createAsyncThunk(`${NAMESPACE}/getWallet`, async () => {
  const cache = new Cache();
  const uid = getUID();
  const cacheResponse = await cache.get(`b:${uid}`);

  if (cacheResponse === undefined || cache.isCachedExpired(cacheResponse.timeCached)) {
    const { data } = await Api.get(`/wallet/balance/all`);
    return data.payload;
  }
  return cacheResponse.payload;
});

const balanceSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {
    resetState: (state, _) => {
      state.getBalanceStatus = HTTP_STATUS.IDLE;
      state.getBalanceMessage = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBalances.pending, (state, action) => {
        state.getBalanceStatus = HTTP_STATUS.LOADING;
      })
      .addCase(getBalances.fulfilled, (state, action) => {
        const uid = getUID();
        new Cache().put(`b:${uid}`, action.payload);
        state.totalBalance = action.payload.total;
        state.balances = action.payload.balances;
        state.getBalanceStatus = HTTP_STATUS.DONE;
      })
      .addCase(getBalances.rejected, (state, { error }) => {
        state.getBalanceStatus = HTTP_STATUS.ERROR;
        state.getBalanceMessage =
          error.code === 'ECONNABORTED'
            ? 'Connection timeout. Check your internet connection.'
            : error.message ?? 'SERVER ERROR';
      });
  },
});

export const { resetState } = balanceSlice.actions;

export default balanceSlice.reducer;
