import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { Api, HTTP_STATUS } from '../../../shared';
import { Cache } from '../../../cache';

const NAMESPACE = 'notificationSettings';

export const getNotificationSettings = createAsyncThunk(`${NAMESPACE}/current`, async () => {
  const cache = new Cache();
  const cacheResponse = await cache.get('notificationSettings');
  if (cacheResponse === undefined || cache.isCachedExpired(cacheResponse.timeCached)) {
    const { data } = await Api.get('/asset?assetName=bitcoin');
    return {
      smsAlerts: true,
      emailAlert: false,
      tradeAlert: true,
      activities: false,
      newsletter: false,
    };
  }

  return cacheResponse.payload;
});

export const updateNotificationSettings = createAsyncThunk(`${NAMESPACE}/update`, async (settings, thunkAPI) => {
  // const { data } = await Api.post('/');
  return settings;
});

const notificationSettingsSlice = createSlice({
  name: NAMESPACE,
  initialState: {
    notifications: {
      smsAlerts: false,
      emailAlerts: false,
      tradeAlert: false,
      activities: false,
      newsletter: false,
    },
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getNotificationSettings.fulfilled, (state, action) => {
        new Cache().put('notificationSettings', action.payload);
        state.notifications = action.payload;
      })
      .addCase(updateNotificationSettings.fulfilled, (state, action) => {
        new Cache().put('notificationSettings', action.payload);
        state.notifications = action.payload;
      });
  },
});

export default notificationSettingsSlice.reducer;
