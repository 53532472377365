import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { Api, HTTP_STATUS } from '../../../shared';

const NAMESPACE = 'offer';

const initialState = {
  offerSummary: undefined,
  getgetOfferSummaryStatus: HTTP_STATUS.IDLE,
  message: '',
};

export const getOfferSummary = createAsyncThunk(`${NAMESPACE}/offerSummary`, async (offerId) => {
  let { data } = await Api.get(`/offer?offerId=${offerId}`);
  return data.payload;
});

const createOfferSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getOfferSummary.pending, (state, action) => {
        state.getOfferSummaryStatus = HTTP_STATUS.LOADING;
      })
      .addCase(getOfferSummary.fulfilled, (state, action) => {
        state.offerSummary = action?.payload || {};
        console.log({ offerSummary: state.offerSummary });
        state.getOfferSummaryStatus = HTTP_STATUS.DONE;
      })
      .addCase(getOfferSummary.rejected, (state, { error }) => {
        state.getOfferSummaryStatus = HTTP_STATUS.ERROR;
        state.message =
          error.code === 'ECONNABORTED'
            ? 'Connection timeout. Check your internet connection.'
            : error.message ?? 'SERVER ERROR';
      });
  },
});

export default createOfferSlice.reducer;
