import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import * as yup from 'yup';
import { Api, HTTP_STATUS } from '../../shared';

const namespace = 'user';

export const getUserDetails = createAsyncThunk(`${namespace}/get`, async (userId) => {
  const qs = [undefined, null, ''].includes(userId) ? {} : { userId: userId.trim() };
  const { data } = await Api.get('/me', {
    params: {
      ...qs,
    },
  });
  return data;
});

export const updateUsername = createAsyncThunk(`${namespace}/updateUsername`, async (username, thunkAPI) => {
  // parameter schema
  const usernameSchema = yup.object().shape({
    username: yup.string().required(),
  });
  await usernameSchema.validate({ username: username });
  const { data } = await Api.put('/user', { username: username });
  return details;
});

const userSlice = createSlice({
  name: namespace,
  initialState: {
    userDetails: undefined,
    getUserStatus: HTTP_STATUS.IDLE,
    message: {},
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getUserDetails.pending, (state, action) => {
        state.getUserStatus = HTTP_STATUS.LOADING;
      })
      .addCase(getUserDetails.fulfilled, (state, action) => {
        const { status, description, payload } = action.payload;
        if (status.toLowerCase() === 'success') {
          state.getUserStatus = HTTP_STATUS.DONE;
          state.userDetails = payload;
        } else {
          state.getUserStatus = HTTP_STATUS.ERROR;
          state.message = description;
        }
      })
      .addCase(getUserDetails.rejected, (state, { error }) => {
        state.getUserStatus = HTTP_STATUS.ERROR;
        state.message =
          error.code === 'ECONNABORTED'
            ? 'Connection timeout. Check your internet connection.'
            : error.message ?? 'SERVER ERROR';
      });
  },
});

export default userSlice.reducer;
