import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { Api, HTTP_STATUS } from '../../../shared';

const NAMESPACE = 'getOffers';

const initialState = {
  getOffersStatus: HTTP_STATUS.IDLE,
  offers: null,
  getOffersMessage: null,
};

export const getOffers = createAsyncThunk(`${NAMESPACE}`, async (offerQuery) => {
  const { data } = await Api.get('/offers', {
    params: {
      assetSymbol: offerQuery.symbol,
      before: offerQuery?.before ?? 'null',
      after: offerQuery?.after ?? 'null',
      limit: 10,
      status: 'active',
      offerType: offerQuery?.offerType ?? 'buy',
      // use alpha2code going forward instead of slug
      country: offerQuery.country,
    },
  });
  return data.payload;
});

const getOfferSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {
    resetGerOfferState(action) {
      state.getOffersStatus = HTTP_STATUS.IDLE;
      state.getOffersMessage = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getOffers.pending, (state, action) => {
        state.getOffersStatus = HTTP_STATUS.LOADING;
      })
      .addCase(getOffers.rejected, (state, { error }) => {
        state.getOffersStatus = HTTP_STATUS.ERROR;
        state.getOffersMessage = !error.status ? 'Error with your network connection' : error.message ?? 'SERVER ERROR';
      })
      .addCase(getOffers.fulfilled, (state, action) => {
        state.getOffersStatus = HTTP_STATUS.DONE;
        state.offers = action.payload;
      });
  },
});

export default getOfferSlice.reducer;
