import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { Api, HTTP_STATUS } from '../../shared';
import { Cache } from '../../cache';

const namespace = 'banks';
const indexdbBanksKey = 'banks';

const initialState = {
  banks: [],
  selectedBank: {},
  status: HTTP_STATUS.IDLE,
};

export const fetchBanks = createAsyncThunk(`${namespace}/available`, async () => {
  const cache = new Cache();
  const cacheResponse = await cache.get(indexdbBanksKey);
  if (cacheResponse === undefined || cache.isCachedExpired(cacheResponse.timeCached)) {
    const { data } = await Api.get('/banks/country?countryName=nigeria');
    return data.payload;
  }
  return cacheResponse.payload;
});

const bankSlice = createSlice({
  name: namespace,
  initialState,
  reducers: {
    setBankSelection(state, { payload }) {
      state.selectedBank = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchBanks.pending, (state, action) => {
        state.status = HTTP_STATUS.LOADING;
      })
      .addCase(fetchBanks.rejected, (state, { error }) => {
        state.status = HTTP_STATUS.ERROR;
        state.message = !error.status ? 'Error with your network connection' : error.message ?? 'SERVER ERROR';
      })
      .addCase(fetchBanks.fulfilled, (state, action) => {
        state.status = HTTP_STATUS.DONE;

        new Cache().put(indexdbBanksKey, action.payload);
        state.banks = action.payload;
      });
  },
});

export const { setBankSelection } = bankSlice.actions;

export default bankSlice.reducer;
