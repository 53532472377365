/**
 * Organization: Coinkudi Limited
 *
 * Prevents unauthenticated user from visiting restricted page
 * Makes request to
 */

import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

import Spinner from '../components/spinner';
import { getAuthToken, HTTP_STATUS, AUTHENTICATION_STATUS } from '../shared';
import { verifyJWT, unAuthenticate } from '../modules/auth/slices/authSlice';

const RouteRenderer = ({ location, children }) => {
  const dispatch = useDispatch();
  const { authenticated, status: authStatus } = useSelector((state) => state.auth);

  const token = getAuthToken();
  React.useEffect(() => {
    // if (AUTHENTICATION_STATUS.UNKNOWN === authenticated && HTTP_STATUS.IDLE === authStatus){}
    if (!token) {
      dispatch(unAuthenticate());
    } else {
      // make request to verify and validate token
      dispatch(verifyJWT());
    }
  }, []);

  React.useEffect(() => {
    if (authStatus === HTTP_STATUS.ERROR) {
      dispatch(unAuthenticate());
    }
  }, [authStatus]);

  const to = {
    pathname: '/auth/get-started',
    state: { from: location },
  };

  if (authenticated === AUTHENTICATION_STATUS.UN_AUTHENTICATED && HTTP_STATUS.DONE === authStatus) {
    return <Redirect to={to} />;
  } else if (authenticated === AUTHENTICATION_STATUS.AUTHENTICATED && HTTP_STATUS.DONE === authStatus) {
    return <React.Fragment>{children}</React.Fragment>;
  } else if (authenticated === AUTHENTICATION_STATUS.UNKNOWN && HTTP_STATUS.ERROR === authStatus) {
  } else if (authenticated === AUTHENTICATION_STATUS.VERIFYING && HTTP_STATUS.LOADING === authStatus) {
    return <Spinner message='Please wait' />;
  } else if (authenticated === AUTHENTICATION_STATUS.UN_AUTHENTICATED && HTTP_STATUS.IDLE === authStatus) {
    // no token in local storage - token has expired
    return <Redirect to={to} />;
  } else {
    return <Spinner />;
  }
  // if (!authenticated && HTTP_STATUS.DONE !== authStatus) {
  // }
};

const RestrictUnauthenticatedUserRoute = ({ children, ...rest }) => {
  return <Route {...rest} render={({ location }) => <RouteRenderer children={children} location={location} />} />;
};

export default RestrictUnauthenticatedUserRoute;
