import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { Api, HTTP_STATUS } from '../../../shared';
import { Cache } from '../../../cache';

const NAMESPACE = 'paymentMethod';
const CACHE_KEY = 'paymentMethods';

const initialState = {
  paymentMethods: [],
  selectedMethod: {},
  payLoadingStatus: HTTP_STATUS.IDLE,
};

export const getPaymentMethods = createAsyncThunk(`${NAMESPACE}/get`, async () => {
  const cache = new Cache();
  const cacheResponse = await cache.get(CACHE_KEY);

  if (cacheResponse === undefined || cache.isCachedExpired(cacheResponse.timeCached)) {
    const { data } = await Api.get('/payment_methods?countryName=nigeria');
    return data.payload;
  }
  return cacheResponse.payload;
});

const paymentMethodsSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {
    setPaymentMethodSelection(state, { payload }) {
      state.selectedMethod = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPaymentMethods.pending, (state, action) => {
        state.payLoadingStatus = HTTP_STATUS.LOADING;
      })
      .addCase(getPaymentMethods.rejected, (state, { error }) => {
        console.log({ error });
        state.payLoadingStatus = HTTP_STATUS.ERROR;
        state.message = !error.status ? 'Error with your network connection' : error.message ?? 'SERVER ERROR';
      })
      .addCase(getPaymentMethods.fulfilled, (state, action) => {
        state.payLoadingStatus = HTTP_STATUS.DONE;
        new Cache().put(CACHE_KEY, action.payload);
        state.paymentMethods = action.payload;
      });
  },
});

export const { setPaymentMethodSelection } = paymentMethodsSlice.actions;

export default paymentMethodsSlice.reducer;
