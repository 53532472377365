/**
 * Organization: Coinkudi Limited
 *
 * Prevents authenticated user from navigating to sign-up and login page
 */

import React, { Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

import Spinner from '../components/spinner';
import { getAuthToken, HTTP_STATUS, AUTHENTICATION_STATUS } from '../shared';
import { verifyJWT } from '../modules/auth/slices/authSlice';

const RouteRenderer = (props) => {
  const { location, children } = props;
  const dispatch = useDispatch();
  const { authenticated, status: authStatus } = useSelector((state) => state.auth);

  const token = getAuthToken();
  React.useEffect(() => {
    if (token) {
      // make request to verify and validate token
      dispatch(verifyJWT());
    }
  }, []);

  return (
    <Fragment>
      {token && HTTP_STATUS.LOADING === authStatus ? (
        <Spinner message='verifing auth state' />
      ) : (
        <React.Fragment></React.Fragment>
      )}
      {[AUTHENTICATION_STATUS.UNKNOWN, AUTHENTICATION_STATUS.UN_AUTHENTICATED].includes(authenticated) && children}
      {authenticated === AUTHENTICATION_STATUS.AUTHENTICATED && (
        <Redirect
          to={{
            pathname: '/dashboard',
            state: { from: location },
          }}
        />
      )}
    </Fragment>
  );
};

const RestrictAuthenticatedUserRoute = ({ children, ...rest }) => {
  return <Route {...rest} render={({ location }) => <RouteRenderer children={children} location={location} />} />;
};

export default RestrictAuthenticatedUserRoute;
