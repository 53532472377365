import * as yup from 'yup';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { Api, getUID, HTTP_STATUS } from '../../../shared';
import { Cache } from '../../../cache';

const NAMESPACE = 'wallet';

const initialState = {
  getWalletStatus: HTTP_STATUS.IDLE,
  getAllWalletStatus: HTTP_STATUS.IDLE,
  wallet: undefined,
  wallets: [],
  errorMessage: undefined,
};

export const getWallet = createAsyncThunk(`${NAMESPACE}/getWallet`, async ({ coinSymbol, network, isToken }) => {
  const walletSchema = yup.object().shape({
    coinSymbol: yup.string().required(),
    isToken: yup.boolean().required(),
    network: yup.string().required(),
  });

  await walletSchema.validate({
    coinSymbol,
    network,
    isToken,
  });

  const cache = new Cache();
  const uid = getUID();
  const cacheResponse = await cache.get(`${coinSymbol}:${uid}`);

  if (cacheResponse === undefined || cache.isCachedExpired(cacheResponse.timeCached)) {
    const { data } = await Api.get(`/wallet?coinSymbol=${coinSymbol}&network=${network}&isToken=${!isToken ? 0 : 1}`);
    return data.payload;
  }
  return cacheResponse.payload;
});

export const getAllWallet = createAsyncThunk(`${NAMESPACE}/getAllWallet`, async () => {
  // endpoint has been closed by the backend team.
  // reach out if you needit re-opened
  const { data } = await Api.get('/wallet/all');
  console.log(data, 'wallet data');

  return data.payload;
});

const getWalletSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getWallet.pending, (state, action) => {
        state.getWalletStatus = HTTP_STATUS.LOADING;
      })
      .addCase(getWallet.fulfilled, (state, action) => {
        state.getWalletStatus = HTTP_STATUS.DONE;
        state.wallet = action.payload;
      })
      .addCase(getWallet.rejected, (state, { error }) => {
        state.getWalletStatus = HTTP_STATUS.ERROR;
        state.errorMessage =
          error.code === 'ECONNABORTED'
            ? 'Connection timeout. Check your internet connection.'
            : error.message ?? 'SERVER ERROR';
      })
      .addCase(getAllWallet.pending, (state, action) => {
        state.getAllWalletStatus = HTTP_STATUS.LOADING;
      })
      .addCase(getAllWallet.fulfilled, (state, action) => {
        state.getAllWalletStatus = HTTP_STATUS.DONE;
        state.wallets = action.payload;
      })
      .addCase(getAllWallet.rejected, (state, { error }) => {
        state.getAllWalletStatus = HTTP_STATUS.ERROR;
        state.errorMessage =
          error.code === 'ECONNABORTED'
            ? 'Connection timeout. Check your internet connection.'
            : error.message ?? 'SERVER ERROR';
      });
  },
});

export default getWalletSlice.reducer;
