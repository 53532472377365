import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { Api, HTTP_STATUS } from '../../shared';
import { Cache } from '../../cache';

const NAMESPACE = 'country';

const initialState = {
  status: HTTP_STATUS.IDLE,
  message: '',
  countries: [],
  country: null,
};

export const getCountries = createAsyncThunk(`${NAMESPACE}/fetchData`, async () => {
  const cache = new Cache();
  const cacheResponse = await cache.get('countries');

  if (cacheResponse === undefined || cache.isCachedExpired(cacheResponse.timeCached)) {
    const { data } = await Api.get('/countries');
    return data.payload;
  }

  return cacheResponse.payload;
});

export const getCountry = createAsyncThunk(`${NAMESPACE}/fromCache`, async () => {
  // todo:  What happens when there is no country
  // todo:  make request to backend to fetch countries associted with this user
  const cacheResp = await new Cache().get('country');
  if (!cacheResp) {
    // make network request to backend
    const _defaultCountry = {
      alpha2Code: 'NG',
      currency: { code: 'NGN', name: 'Nigerian naira', symbol: '₦' },
      name: 'Nigeria',
      slug: 'nigeria',
    };
    await new Cache().put('country', _defaultCountry);
  }
  return cacheResp.payload;
});

export const putCountry = createAsyncThunk(`${NAMESPACE}/toCache`, async () => {});

const countrySlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCountries.pending, (state) => {
        state.status = HTTP_STATUS.LOADING;
      })
      .addCase(getCountries.rejected, (state, { error }) => {
        state.message = !error.status ? 'Error with your network connection' : error.message ?? 'SERVER ERROR';
        state.status = HTTP_STATUS.ERROR;
      })
      .addCase(getCountries.fulfilled, (state, action) => {
        new Cache().put('countries', action.payload);
        state.countries = action.payload;
      })
      .addCase(getCountry.pending, (state, action) => {
        state.status = HTTP_STATUS.PENDING;
      })
      .addCase(getCountry.fulfilled, (state, action) => {
        state.country = action.payload;
        state.status = HTTP_STATUS.DONE;
      })
      .addCase(getCountry.rejected, (state, { error }) => {
        state.status = HTTP_STATUS.ERROR;
        state.message = !error.status ? 'Error with your network connection' : error.message ?? 'SERVER ERROR';
      });
  },
});

export const { country } = countrySlice.actions;

export default countrySlice.reducer;
