import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  value: false,
};

const sideNavToggleSlice = createSlice({
  name: 'sideNav',
  initialState,
  reducers: {
    toggleSideNav(state, action) {
      state.value = action.payload;
    },
  },
});

export const { toggleSideNav } = sideNavToggleSlice.actions;

export default sideNavToggleSlice.reducer;
