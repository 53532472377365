const shadow = {
  xs: '0px 4px 8px rgba(0,0,0,0.12)',
  sm: '0 5px 10px rgba(0,0,0,0.12)',
  md: '0 8px 30px rgba(0,0,0,0.12)',
  lg: '0 30px 60px rgba(0,0,0,0.12)',
  xl: '0 30px 60px rgba(0,0,0,0.12)',
  btn: '0 3px 6px 0 rgba(0,0,0,0.05)',
};

export default shadow;
