/**
 * The links provides visual represention of the color swatchs
 * The base swatch is in use
 */
const colors = {
  transparent: 'transparent',
  black: '#000000',
  white: '#ffffff',
  // self generated (@ukor)
  offWhite: {
    500: '#fafafa',
    900: '#f3f3f3',
  },
  // https://smart-swatch.netlify.app/#fafafa
  gray: {
    25: '#f6f6f6',
    50: '#f2f2f2',
    100: '#d9d9d9',
    200: '#bfbfbf',
    300: '#a6a6a6',
    400: '#8c8c8c',
    500: '#737373',
    600: '#595959',
    700: '#404040',
    800: '#262626',
    900: '#0d0d0d',
  },
  // https://smart-swatch.netlify.app/#0066fa
  blue: {
    25: '#e2eaf6', // custom added
    50: '#def1ff',
    100: '#afd2ff',
    200: '#7db5ff',
    300: '#4b97ff',
    400: '#1a79ff',
    500: '#0060e6',
    600: '#004ab4',
    700: '#003582',
    800: '#002051',
    900: '#000b21',
  },
  // https://smart-swatch.netlify.app/#ED1C24
  red: {
    50: '#ffe3e5',
    100: '#feb6b9',
    200: '#f8888c',
    300: '#f3595e',
    400: '#ee2a32',
    500: '#d51118',
    600: '#a60a12',
    700: '#77050d',
    800: '#4a0205',
    900: '#200000',
  },
  // https://smart-swatch.netlify.app/#4BB543
  green: {
    50: '#e7fae5',
    100: '#c7ebc3',
    200: '#a4dda0',
    300: '#81cf7c',
    400: '#5fc158',
    500: '#45a73e',
    600: '#35822f',
    700: '#255d20',
    800: '#133912',
    900: '#001500',
  },
};

export default colors;
