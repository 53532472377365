import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import * as yup from 'yup';
import { Api, HTTP_STATUS } from '../../shared';

const NAMESPACE = 'phoneNumber';

export const updatePhoneNumber = createAsyncThunk(`${NAMESPACE}/put`, async (phoneNumber) => {
  const phoneNumberSchema = yup.object().shape({
    phoneNumber: yup.number().required(),
  });

  const req_payload = { phoneNumber };

  await phoneNumberSchema.validate(req_payload);
  const { data } = await Api.post('/user/phone_number', req_payload);
  return data;
});

export const verifyPhoneNumber = createAsyncThunk(`${NAMESPACE}/verify`, async (otp) => {
  const verifyPhoneNumberSchema = yup.object().shape({
    otp: yup.number().required(),
  });

  const req_payload = { otp };

  await verifyPhoneNumberSchema.validate(req_payload);
  const { data } = await Api.post('/user/phone_number/verify', req_payload);
  return data;
});

const settingsSlice = createSlice({
  name: NAMESPACE,
  initialState: {
    updatePhoneNumberStatus: HTTP_STATUS.IDLE,
    verifyPhoneNumberStatus: HTTP_STATUS.IDLE,
    message: undefined,
    isOTPverifed: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(updatePhoneNumber.pending, (state, action) => {
        state.updatePhoneNumberStatus = HTTP_STATUS.LOADING;
      })
      .addCase(updatePhoneNumber.fulfilled, (state, action) => {
        const { status, description, payload } = action.payload;
        if (status.toLowerCase() === 'success') {
          state.updatePhoneNumberStatus = HTTP_STATUS.DONE;
        } else {
          state.updatePhoneNumberStatus = HTTP_STATUS.ERROR;
          state.message = description;
        }
      })
      .addCase(updatePhoneNumber.rejected, (state, { error }) => {
        state.updatePhoneNumberStatus = HTTP_STATUS.ERROR;
        state.message =
          error.code === 'ECONNABORTED'
            ? 'Connection timeout. Check your internet connection.'
            : error.message ?? 'SERVER ERROR';
      })
      .addCase(verifyPhoneNumber.pending, (state, action) => {
        state.verifyPhoneNumberStatus = HTTP_STATUS.LOADING;
      })
      .addCase(verifyPhoneNumber.fulfilled, (state, action) => {
        const { status, description, payload } = action.payload;
        console.log(status, description, payload, 'omo see state', action.payload);
        if (status.toLowerCase() === 'success') {
          state.verifyPhoneNumberStatus = HTTP_STATUS.DONE;
          state.isOTPverifed = true;
        } else {
          state.verifyPhoneNumberStatus = HTTP_STATUS.ERROR;
          state.message = description;
        }
      })
      .addCase(verifyPhoneNumber.rejected, (state, { error }) => {
        state.verifyPhoneNumberStatus = HTTP_STATUS.ERROR;
        state.message =
          error.code === 'ECONNABORTED'
            ? 'Connection timeout. Check your internet connection.'
            : error.message ?? 'SERVER ERROR';
      });
  },
});

export default settingsSlice.reducer;
