import { createSlice } from '@reduxjs/toolkit';

const NAMESPACE = 'network';

const initialState = {
  walletNetwork: '',
};

const networkSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {
    toggleNetwork(state, action) {
      state.walletNetwork = action.payload;
    },
  },
});

export const { toggleNetwork } = networkSlice.actions;

export default networkSlice.reducer;
