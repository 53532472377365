import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { Api, HTTP_STATUS } from '../../../shared';

const NAMESPACE = 'offerActions';

const initialState = {
  cancelOfferStatus: HTTP_STATUS.IDLE,
  cancelOfferMessage: null,
  pauseOfferStatus: HTTP_STATUS.IDLE,
  pauseOfferMessage: null,
};

export const pauseOffer = createAsyncThunk(`${NAMESPACE}/pause`, async (offerId) => {
  const { data } = await Api.put(`/offer/pause`, { offerId });
  return data.payload;
});

export const cancelOffer = createAsyncThunk(`${NAMESPACE}/cancel`, async (offerId) => {
  const { data } = await Api.put(`/offer/cancel`, { offerId });
  return data.payload;
});

const offerActionSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {
    resetPauseState(state, _) {
      state.pauseOfferStatus = HTTP_STATUS.IDLE;
      state.pauseOfferMessage = null;
    },
    resetCancelState(state, _) {
      state.cancelOfferStatus = HTTP_STATUS.IDLE;
      state.cancelOfferMessage = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(pauseOffer.pending, (state, action) => {
        state.pauseOfferStatus = HTTP_STATUS.LOADING;
      })
      .addCase(pauseOffer.fulfilled, (state, action) => {
        console.log(action.payload);
        state.pauseOfferStatus = HTTP_STATUS.DONE;
      })
      .addCase(pauseOffer.rejected, (state, { error }) => {
        state.pauseOfferStatus = HTTP_STATUS.ERROR;
        state.pauseOfferMessage = !error.status
          ? 'Error with your network connection'
          : error.message ?? 'SERVER ERROR';
      })
      .addCase(cancelOffer.pending, (state, action) => {
        state.cancelOfferStatus = HTTP_STATUS.LOADING;
      })
      .addCase(cancelOffer.fulfilled, (state, action) => {
        console.log(action.payload);
        state.cancelOfferStatus = HTTP_STATUS.DONE;
      })
      .addCase(cancelOffer.rejected, (state, { error }) => {
        state.cancelOfferStatus = HTTP_STATUS.ERROR;
        state.cancelOfferMessage = !error.status
          ? 'Error with your network connection'
          : error.message ?? 'SERVER ERROR';
      });
  },
});

export const { resetPauseState, resetCancelState } = offerActionSlice.actions;

export default offerActionSlice.reducer;
