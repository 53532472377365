import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { Api, HTTP_STATUS } from '../../../shared';

const NAMESPACE = 'submitReview';

const initialState = {
  submitReviewLoadingStatus: HTTP_STATUS.IDLE,
  errorMessage: null,
};

export const submitReview = createAsyncThunk(`${NAMESPACE}`, async (review) => {
  await Api.post('/user/feedback', review);
});

const submitReviewSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(submitReview.pending, (state, action) => {
        state.submitReviewLoadingStatus = HTTP_STATUS.LOADING;
      })
      .addCase(submitReview.rejected, (state, { error }) => {
        state.submitReviewLoadingStatus = HTTP_STATUS.ERROR;
        state.errorMessage = !error.status ? 'Error with your network connection' : error.message ?? 'SERVER ERROR';
      })
      .addCase(submitReview.fulfilled, (state, action) => {
        state.submitReviewLoadingStatus = HTTP_STATUS.DONE;
      });
  },
});

export default submitReviewSlice.reducer;
