import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { Api, HTTP_STATUS } from '../../../shared';
import { offerDetailsSchema, bankAccountDetailsSchema, paymentMethodSchema } from '../../../shared/yupValidationRules';

const NAMESPACE = 'offer';

const initialState = {
  createOfferStatus: '',
  message: '',
  status: '',
  offers: [],
};

export const postOffer = createAsyncThunk(`${NAMESPACE}/create`, async (offerDetails, thunkAPI) => {
  await paymentMethodSchema.validate(offerDetails.paymentDetails);
  if (
    offerDetails.offerType === 'sell' &&
    offerDetails.paymentMethod.includes('Bank Transfer') &&
    offerDetails.paymentDetailsId === ''
  ) {
    await bankAccountDetailsSchema.validate(offerDetails.paymentDetails);
  }

  await offerDetailsSchema.validate(offerDetails);
  const { data } = await Api.post('/offer', offerDetails);
  return data;
});

const createOfferSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {
    changeCreateOfferLoadingStatus(state, action) {
      state.createOfferStatus = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(postOffer.pending, (state, action) => {
        state.createOfferStatus = HTTP_STATUS.LOADING;
      })
      .addCase(postOffer.fulfilled, (state, action) => {
        const { status, description, _ } = action.payload;
        if (status.toLowerCase() === 'success') {
          state.createOfferStatus = HTTP_STATUS.DONE;
        } else {
          state.createOfferStatus = HTTP_STATUS.ERROR;
          state.message = description;
        }
      })
      .addCase(postOffer.rejected, (state, { error }) => {
        state.createOfferStatus = HTTP_STATUS.ERROR;
        state.message =
          error.code === 'ECONNABORTED'
            ? 'Connection timeout. Check your internet connection.'
            : error.message ?? 'SERVER ERROR';
      });
  },
});

export const { changeCreateOfferLoadingStatus } = createOfferSlice.actions;
export default createOfferSlice.reducer;
