import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import Spinner from './components/spinner';
import { RestrictAuthenticatedUserRoute, RestrictUnauthenticatedUserRoute } from './shared';

const LandingPage = lazy( () => import( './modules/landingPage' ) );
const Homepage = lazy( () => import( './modules/homepage' ) );
const SignUpPage = lazy( () => import( './modules/auth/signupPage' ) );
// const SignInPage = lazy(() => import('./modules/auth/signInPage'));
const AuthProceed = lazy( () => import( './modules/auth/authProceedPage' ) );
const PasswordlessVerification = lazy( () => import( './modules/auth/passwordlessVerification' ) );
const MarketPage = lazy( () => import( './modules/market' ) );
const PaymentPage = lazy( () => import( './modules/payment' ) );
const SendCrypto = lazy( () => import( './modules/wallet/sendCrypto' ) );
const AdvertiserDetailsPage = lazy( () => import( './modules/advertiser' ) );
const StartTradePage = lazy( () => import( './modules/trade' ) );
const WalletPage = lazy( () => import( './modules/wallet' ) );
const Dashboard = lazy( () => import( './modules/dashboard/dashboard.v4' ) );
const TransactionHistory = lazy( () => import( './modules/transactionHistory' ) );
const CreateOffer = lazy( () => import( './modules/createOffer' ) );
const MyOffer = lazy( () => import( './modules/myOffer' ) );
const SettingsPage = lazy( () => import( './modules/settings' ) );
const ActiveTradePage = lazy( () => import( './modules/activeTrade' ) );
const TradeHistory = lazy( () => import( './modules/tradeHistory' ) );
const ReferralBenefits = lazy( () => import( './modules/referral' ) );
const TermsOfService = lazy( () => import( './modules/terms' ) );
const KycLegal = lazy( () => import( './modules/kycLegal' ) );
const PrivacyPolicy = lazy( () => import( './modules/privacy' ) );
const ApiLegal = lazy( () => import( './modules/apiLegal' ) );

const PageNotFound = lazy( () => import( './modules/errors' ) );
const App = () => {
  return (
    <Suspense fallback={<Spinner message='building page...' />}>
      <Router>
        <Switch>
          <Route exact path='/'>
            <LandingPage />
          </Route>
          <Route exact path='/home'>
            <Homepage />
          </Route>

          <RestrictUnauthenticatedUserRoute exact path='/offers'>
            <MarketPage />
          </RestrictUnauthenticatedUserRoute>
          <RestrictUnauthenticatedUserRoute exact path='/offers/buy'>
            <MarketPage />
          </RestrictUnauthenticatedUserRoute>
          <RestrictUnauthenticatedUserRoute exact path='/offers/sell'>
            <MarketPage />
          </RestrictUnauthenticatedUserRoute>

          <Route exact path='/auth/passwordless/verify'>
            <PasswordlessVerification />
          </Route>
          {/*  */}

          <RestrictAuthenticatedUserRoute exact path='/auth/proceed'>
            <AuthProceed />
          </RestrictAuthenticatedUserRoute>
          <RestrictAuthenticatedUserRoute exact path='/auth/get-started'>
            <SignUpPage />
          </RestrictAuthenticatedUserRoute>
          {/* Depricated in favour for single auth flow */}
          {/* <RestrictAuthenticatedUserRoute exact path='/auth/login'>
            <SignInPage />
          </RestrictAuthenticatedUserRoute> */}
          <RestrictUnauthenticatedUserRoute exact path='/trade/payment'>
            <PaymentPage />
          </RestrictUnauthenticatedUserRoute>
          <RestrictUnauthenticatedUserRoute exact path='/wallet/send'>
            <SendCrypto />
          </RestrictUnauthenticatedUserRoute>
          <RestrictUnauthenticatedUserRoute exact path='/advertiser'>
            <AdvertiserDetailsPage />
          </RestrictUnauthenticatedUserRoute>
          <RestrictUnauthenticatedUserRoute exact path='/trade/start/:offerId'>
            <StartTradePage />
          </RestrictUnauthenticatedUserRoute>
          <RestrictUnauthenticatedUserRoute exact path='/trade/history'>
            <TradeHistory />
          </RestrictUnauthenticatedUserRoute>
          <RestrictUnauthenticatedUserRoute exact path='/dashboard'>
            <Dashboard />
          </RestrictUnauthenticatedUserRoute>
          <RestrictUnauthenticatedUserRoute exact path='/tx/history'>
            <TransactionHistory />
          </RestrictUnauthenticatedUserRoute>
          <RestrictUnauthenticatedUserRoute exact path='/settings'>
            <SettingsPage />
          </RestrictUnauthenticatedUserRoute>
          <RestrictUnauthenticatedUserRoute exact path='/create-offer'>
            <CreateOffer />
          </RestrictUnauthenticatedUserRoute>
          <RestrictUnauthenticatedUserRoute exact path='/user/offers'>
            <MyOffer />
          </RestrictUnauthenticatedUserRoute>
          <Route exact path='/referral-benefits'>
            <ReferralBenefits />
          </Route>
          <RestrictUnauthenticatedUserRoute exact path='/wallet'>
            <WalletPage />
          </RestrictUnauthenticatedUserRoute>
          <Route exact path='/terms'>
            <TermsOfService />
          </Route>
          <Route exact path='/kyc-legal'>
            <KycLegal />
          </Route>
          <Route exact path='/company'>
            <PrivacyPolicy />
          </Route>
          <Route exact path="/api-legal">
            <ApiLegal />
          </Route>
          {/*  Error pages */}
          <Route exact path='/*'>
            <PageNotFound />
          </Route>
        </Switch>
      </Router>
    </Suspense>
  );
};

export default App;
