import boswer from 'bowser';

export const HTTP_STATUS = Object.freeze({
  IDLE: 'IDLE',
  LOADING: 'LOADING',
  DONE: 'DONE',
  ERROR: 'ERROR',
});

export const AUTHENTICATION_STATUS = Object.freeze({
  UNKNOWN: 'UNKNOWN',
  VERIFYING: 'VERIFYING',
  AUTHENTICATED: 'AUTHENTICATED',
  UN_AUTHENTICATED: 'UN_AUTHENTICATED',
});

// the minimum amount that can be created as offer
export const OFFER_LIMIT = Object.freeze({
  btc: 0.0005,
  eth: 0.0005,
  usdt: 5,
});
// minimum amount that can be traded
export const TRADE_LIMIT = Object.freeze({
  ngn: 10000,
});

export const profitPercentage = 0.5;

export const queryStringOptions = Object.freeze({ arrayFormat: 'comma', skipNull: true });

const ua = boswer.parse(window.navigator.userAgent);
export const deviceDetails = Object.freeze({
  deviceName: `${ua.browser.name} ${ua.browser.version}`,
  operatingSystem: `${ua.os.name} ${ua.os.version} ${ua.os.versionName}`,
  deviceId:
    `${ua.platform.vendor}-${ua.os.name}-${ua.platform.type}-${ua.browser.name}-${ua.engine.name}`.toLowerCase(),
  IPAddress: '', // remove or fetch from server
});

export const setAuthToken = (t, i) => {
  const token = JSON.stringify({ t, i });
  window.localStorage.setItem('token', token);
};
export const getAuthToken = () => JSON.parse(window.localStorage.getItem('token'));
export const deleteAuthToken = () => window.localStorage.removeItem('token');
export const getUID = () => JSON.parse(window.localStorage.getItem('token') ?? {})?.i;

export const outterSpace = Object.freeze({ base: 3, sm: 3, md: 10, lg: 24, xl: 32 });

export function flexWidth(size) {
  return `calc(100% /(12 / ${size}))`;
}

export default Object.freeze({
  appName: 'Kudipeer',
  twitterUsername: 'coinkudi',
});
