import { Manager } from 'socket.io-client';
import * as endpoints from './endpoints';
import { getAuthToken } from './constant';

const env = process.env.REACT_APP_ENVIRONMENT;
const endpoint = endpoints[env];

// get user JWT token
const j = getAuthToken();
const { t: _token, i: _uid } = j ?? { t: 'NO_TOKEN', i: 'NO_UID' };

const auth = {
  token: _token,
  uid: _uid,
  requestingService: 'coinkudi',
};

const socketEndpoint = endpoint.socket === '' ? 'http://localhost:21011' : endpoint.socket;

const manager = new Manager(socketEndpoint, {
  reconnectionDelay: 5000 * 2,
  query: {},
  path: '/ck-socket',
});

export const socket = manager.socket('/', {
  auth,
});
