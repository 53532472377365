const _baseStyle = {
  outline: 'none',
  color: 'white',
  paddingY: 2,
  paddingX: 4,
  lineHeight: '1.715em',
  transition: 'all 0.2s cubic-bezier(.08,.52,.52,1)',
  borderRadius: 0,
  fontSize: '14px',
  fontWeight: 'semibold',
  background: 'blue.500',
  backgroundColor: 'blue.500',
  _hover: {
    background: 'blue.400',
    backgroundColor: 'blue.400',
    transition: 'background-color .3s cubic-bezier(.25,.8,.5,1),opacity .4s cubic-bezier(.25,.8,.5,1)',
  },
  _active: {
    background: 'blue.500',
    backgroundColor: 'blue.500',
    transform: 'scale(0.98)',
    borderColor: 'blue.100',
  },
  _focus: {
    background: 'blue.400',
    backgroundColor: 'blue.400',
    boxShadow: '0 0 0 3px white, 0 0 0 5px #2861e6cc, 0 0 transparent, 0 0 transparent',
  },
};

const button = {
  // 1. We can update the base styles
  baseStyle: {
    ..._baseStyle,
  },
  // 2. We can add a new button size or extend existing
  sizes: {
    xl: {
      h: '56px',
      fontSize: 'lg',
      px: '32px',
    },
  },
  // 3. We can add a new visual variant
  variants: {
    'with-shadow': {
      bg: 'blue.400',
      boxShadow: '0 0 2px 2px blue.300',
    },
    // 4. We can override existing variants
    outlined: (props) => ({
      color: 'blue.500',
      background: 'white',
      backgroundColor: 'white',
      border: '1px',
      borderColor: 'blue.500',
      _hover: {
        background: 'blue.100',
        backgroundColor: 'blue.100',
        transition: 'background-color .3s cubic-bezier(.25,.8,.5,1),opacity .4s cubic-bezier(.25,.8,.5,1);',
      },
    }),
    default: (props) => ({
      ..._baseStyle,
    }),
    danger: (props) => ({
      background: 'red.500',
      backgroundColor: 'red.500',
      borderColor: 'red.500',
      _hover: { background: 'red.400', backgroundColor: 'red.400' },
      _active: {
        background: 'red.500',
        backgroundColor: 'red.500',
        borderColor: 'red.100',
      },
      _focus: {
        background: 'red.400',
        backgroundColor: 'red.400',
        boxShadow: '0 0 0 3px white, 0 0 0 5px #ee2a32, 0 0 transparent, 0 0 transparent',
      },
    }),
    tab: (props) => ({
      background: props.selected ? 'blue.500' : props.buy ? 'green.500' : props.sell ? 'red.500' : 'white',
      backgroundColor: props.selected ? 'blue.500' : props.buy ? 'green.500' : props.sell ? 'red.500' : 'white',
      color: props.selected || props.buy || props.sell ? 'white' : 'blue.500',
      fontSize: { base: 'sm', md: 'md' },
      fontWeight: '500',
      outline: 'none',
      borderRadius: '20px',
      paddingX: { base: 2, sm: 4 },
      paddingY: 1,
      width: 'max',
      borderColor: '#e1eeff',
      border: 'thin',
      marginX: { base: 1, sm: 2 },
      _focus: {
        boxShadow: 'none',
        background: props.buy ? 'green.500' : props.sell ? 'red.500' : 'blue.500',
        backgroundColor: props.buy ? 'green.500' : props.sell ? 'red.500' : 'blue.500',
      },
      _active: { boxShadow: 'none', background: '#ebf4ff', backgroundColor: '#ebf4ff' },
      _hover: {
        background: props.buy ? 'green.100' : props.sell ? 'red.100' : '#ebf4ff',
        backgroundColor: props.buy ? 'green.100' : props.sell ? 'red.100' : '#ebf4ff',
        color: props.buy ? 'green.500' : props.sell ? 'red.500' : '#0066F5',
      },
    }),
  },
};

export default button;
