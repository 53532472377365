import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { Api, HTTP_STATUS } from '../../shared';

const NAMESPACE = 'tickers';

const initialState = {
  marketPrice: 0.0,
  message: '',
  status: HTTP_STATUS.IDLE,
};

export const getMarketPrice = createAsyncThunk(`${NAMESPACE}/pairs`, async (assetPairs) => {
  const { data } = await Api.get(`/tickers?assetPairs=${assetPairs}`);
  return data.payload;
});

const tickerSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getMarketPrice.pending, (state, action) => {
        state.status = HTTP_STATUS.LOADING;
      })
      .addCase(getMarketPrice.fulfilled, (state, action) => {
        state.marketPrice = parseFloat(action.payload);
        state.status = HTTP_STATUS.DONE;
      })
      .addCase(getMarketPrice.rejected, (state, { error }) => {
        state.status = HTTP_STATUS.ERROR;
        state.message = !error.status ? 'Error with your network connection' : error.message ?? 'SERVER ERROR';
      });
  },
});

export default tickerSlice.reducer;
