import * as yup from 'yup';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { Api, HTTP_STATUS } from '../../../shared';
import { paymentMethodSchema, bankAccountDetailsSchema } from '../../../shared/yupValidationRules';

const NAMESPACE = 'trade';

const initialState = {
  startTradeStatus: '',
  getTradeStatus: HTTP_STATUS.IDLE,
  trade: undefined,
  trades: undefined,
  tradeId: undefined,
  message: undefined,
};

export const startTrade = createAsyncThunk(`${NAMESPACE}/start`, async (tradeDetails) => {
  // parameter schema
  const tradeSchema = yup.object().shape({
    quantity: yup.number().required(),
    amountInFait: yup.number().required(),
    offerId: yup.string().required(),
    status: yup.string().required(),
  });

  await tradeSchema.validate(tradeDetails);

  if (tradeDetails.hasOwnProperty('paymentDetails')) {
    // verify payment details
    await paymentMethodSchema.validate(tradeDetails.paymentDetails);
    if (tradeDetails.paymentDetails.type.toLowerCase() === 'bank') {
      await bankAccountDetailsSchema.validate(tradeDetails.paymentDetails);
    }
  }

  await tradeSchema.validate(tradeDetails);

  const { data } = await Api.post('/trade', tradeDetails);

  return data.payload;
});

export const getTrade = createAsyncThunk(`${NAMESPACE}/getTrade`, async (tradeId) => {
  if (['', null, undefined].includes(tradeId) || typeof tradeId !== 'string') {
    return {
      status: 'error',
      message: 'Invalid tradeId passed',
    };
  }
  const { data } = await Api.get(`/trade?tradeId=${tradeId}`);

  return data.payload;
});

const tradeSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(startTrade.pending, (state, action) => {
        state.startTradeStatus = HTTP_STATUS.LOADING;
      })
      .addCase(startTrade.fulfilled, (state, action) => {
        state.startTradeStatus = HTTP_STATUS.DONE;
        state.tradeId = action.payload;
      })
      .addCase(startTrade.rejected, (state, { error }) => {
        state.startTradeStatus = HTTP_STATUS.ERROR;
        state.tradeId = undefined;
        state.message =
          error.code === 'ECONNABORTED'
            ? 'Connection timeout. Check your internet connection.'
            : error.message ?? 'SERVER ERROR';
      })
      .addCase(getTrade.pending, (state, action) => {
        state.getTradeStatus = HTTP_STATUS.LOADING;
      })
      .addCase(getTrade.fulfilled, (state, action) => {
        state.getTradeStatus = HTTP_STATUS.DONE;
        state.trade = action.payload;
        console.log(action.payload);
      })
      .addCase(getTrade.rejected, (state, { error }) => {
        state.startTradeStatus = HTTP_STATUS.ERROR;
        state.trade = undefined;
        state.message =
          error.code === 'ECONNABORTED'
            ? 'Connection timeout. Check your internet connection.'
            : error?.message ?? 'SERVER ERROR';
      });
  },
});

export default tradeSlice.reducer;
