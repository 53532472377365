import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { Api, HTTP_STATUS } from '../../../shared';

const NAMESPACE = 'userOffer';

const initialState = {
  getUserOfferStatus: HTTP_STATUS.IDLE,
  userOffers: {},
  message: '',
};

export const getUserOffers = createAsyncThunk(`${NAMESPACE}/fetch`, async (query) => {
  //status ('active', 'cancel', 'pause', 'complete')
  const { data } = await Api.get(`/user/offers`, {
    params: {
      ...query,
    },
  });
  return data.payload;
});

const getUserOfferSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getUserOffers.pending, (state, action) => {
        state.getUserOfferStatus = HTTP_STATUS.LOADING;
      })
      .addCase(getUserOffers.rejected, (state, action) => {
        state.getUserOfferStatus = HTTP_STATUS.ERROR;
      })
      .addCase(getUserOffers.fulfilled, (state, action) => {
        console.log(action.payload, 'action');
        state.userOffers = action.payload;
        state.getUserOfferStatus = HTTP_STATUS.DONE;
      });
  },
});

export default getUserOfferSlice.reducer;
