import { createSlice } from '@reduxjs/toolkit';

const NAMESPACE = 'createOffer';

const initialState = {
  offerType: 'buy',
  rate: 0,
  amount: 1,
  selectedCoin: {
    name: 'Bitcoin',
    symbol: 'BTC',
  },
  priceType: 'marketPrice',
  offerMargin: 5,
  minimumTradeAmount: 5000,
  timeLimit: 30,
  tradeInstruction: '',
  paymentDetailsId: '',
  accountNumber: '',
  accountName: '',
};

const createOfferSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {
    setAmount(state, { payload }) {
      state.amount = payload;
    },
    setRate(state, { payload }) {
      state.rate = payload;
    },
    setSelectedCoin(state, { payload }) {
      state.selectedCoin = payload;
    },
    setOfferType(state, { payload }) {
      state.offerType = payload;
    },
    setPriceType(state, { payload }) {
      state.priceType = payload;
    },
    setOfferMargin(state, { payload }) {
      state.offerMargin = payload;
    },
    setMinimumTradeAmount(state, { payload }) {
      state.minimumTradeAmount = payload;
    },
    setTimeLimit(state, { payload }) {
      state.timeLimit = payload;
    },
    setTradeInstruction(state, { payload }) {
      state.tradeInstruction = payload;
    },
    setPaymentDetailsId(state, { payload }) {
      state.paymentDetailsId = payload;
    },
    setAccountName(state, { payload }) {
      state.accountName = payload;
    },
    setAccountNumber(state, { payload }) {
      state.accountNumber = payload;
    },
    resetOfferState(state) {
      state.offerType = 'buy';
      state.rate = 0;
      state.amount = 1;
      state.selectedCoin = {
        name: 'Bitcoin',
        symbol: 'BTC',
      };
      state.priceType = 'market-price';
      state.offerMargin = 5;
      state.minimumTradeAmount = 1500;
      state.timeLimit = 30;
      state.tradeInstruction = '';
      state.paymentDetailsId = '';
      state.accountNumber = '';
      state.accountName = '';
    },
  },
});

export const {
  setAmount,
  setRate,
  setSelectedCoin,
  setOfferType,
  setPriceType,
  setOfferMargin,
  setMinimumTradeAmount,
  setTimeLimit,
  setTradeInstruction,
  setPaymentDetailsId,
  setAccountName,
  setAccountNumber,
  resetOfferState,
} = createOfferSlice.actions;

export default createOfferSlice.reducer;
