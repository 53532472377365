import { createSlice } from '@reduxjs/toolkit';

const NAMESPACE = 'dashboardTabs';

const initialState = {
  activeTab: 'buy',
};

const toggleDashboardTabSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {
    changeDashboardTab(state, action) {
      state.activeTab = action.payload;
    },
  },
});

export const { changeDashboardTab } = toggleDashboardTabSlice.actions;

export default toggleDashboardTabSlice.reducer;
