export const development = Object.freeze({
  api: 'https://coinkudi-gateway-a3syisy3.nw.gateway.dev',
  // this should be sock_dev.coinkudi.com or sock_dev.kudipeer.com
  socket: 'https://sock-staging.kudipeer.com',
});

export const production = Object.freeze({
  api: 'https://coinkudi-gateway-a3syisy3.nw.gateway.dev',
  // todo - this should be sock_prod.kudipeer.com
  socket: 'https://sock-prod.kudipeer.com',
});

export const staging = Object.freeze({
  api: 'https://staging-gateway-a3syisy3.nw.gateway.dev',
  // this should be sock_staging.coinkudi.com or sock_staging.kudipeer.com
  socket: 'sock-staging.kudipeer.com',
});

// todo: WIP
// this won't work because will use microservice
// it will only work if will have
// - API gateway setup on the localhost
// - proxy server with nginx
// - docker

// socket will work fine for testing
export const fs = Object.freeze({
  api: 'http://localhost:20011',
  socket: 'http://localhost:21011',
});
