import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { Api, HTTP_STATUS } from '../../shared';
import { Cache } from '../../cache';

const NAMESPACE = 'cryptoAsset';

const initialState = {
  offerType: 'sell',
  cryptoAssets: [],
  status: HTTP_STATUS.IDLE,
  message: '',
};

export const getCryptoAssets = createAsyncThunk(`${NAMESPACE}/getAssets`, async () => {
  const cache = new Cache();
  const cacheResponse = await cache.get('cryptoAssets');
  if (cacheResponse === undefined || cache.isCachedExpired(cacheResponse.timeCached)) {
    const { data } = await Api.get('/assets');
    return data.payload;
  }
  return cacheResponse.payload;
});

const cryptoAssetSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {
    shiftCoin(state, { payload }) {
      let cutOut = state.cryptoAssets.splice(payload.from, 1)[0];
      state.cryptoAssets.splice(payload.to, 0, cutOut);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCryptoAssets.pending, (state, action) => {
        state.status = HTTP_STATUS.LOADING;
      })
      .addCase(getCryptoAssets.rejected, (state, { error }) => {
        state.status = HTTP_STATUS.ERROR;
        state.message = !error.status ? 'Error with your network connection' : error.message ?? 'SERVER ERROR';
      })
      .addCase(getCryptoAssets.fulfilled, (state, action) => {
        state.status = HTTP_STATUS.DONE;
        new Cache().put('cryptoAssets', action.payload);
        state.cryptoAssets = action.payload;
      });
  },
});

export const { shiftCoin } = cryptoAssetSlice.actions;

export default cryptoAssetSlice.reducer;
