import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { Api, HTTP_STATUS } from '../../shared';

const NAMESPACE = 'market';

const initialState = {
  currentMarketCoin: undefined,
  currentMarketDetails: undefined,
  currentMarketDetailsStatus: HTTP_STATUS.IDLE,
  currentMarketDetailsMessage: undefined,
};

export const getCurrentMarketDetails = createAsyncThunk(
  `${NAMESPACE}/getDetails`,
  async ({ coinSymbol, countryAphaCode }) => {
    // validate inputs
    const { data } = await Api.get(
      `/asset/market?assetSymbol=${coinSymbol.toLowerCase()}&country=${countryAphaCode.toUpperCase()}`
    );
    return data.payload;
  }
);

const toggleMarketSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {
    changeMarketCoin(state, action) {
      state.currentMarketCoin = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCurrentMarketDetails.pending, (state, action) => {
        state.currentMarketDetailsStatus = HTTP_STATUS.LOADING;
      })
      .addCase(getCurrentMarketDetails.fulfilled, (state, action) => {
        state.currentMarketDetails = action.payload;
        state.currentMarketDetailsStatus = HTTP_STATUS.DONE;
      })
      .addCase(getCurrentMarketDetails.rejected, (state, { error }) => {
        state.currentMarketDetailsMessage = !error.status
          ? 'Error with your network connection'
          : error.message ?? 'SERVER ERROR';
        state.currentMarketDetailsStatus = HTTP_STATUS.ERROR;
      });
  },
});

export const { changeMarketCoin } = toggleMarketSlice.actions;

export default toggleMarketSlice.reducer;
