/**
 * This has been change in the backend to a more genric name
 * UserPaymentMethods
 * This way the backend can accomodate for all the type of payment methods
 * supported by coinkudi
 *
 * The user payment method, take a structure like this:
 * {
 *   '_id: 'mongo-object-id',
 *   'type': 'bank',
 *   'details': {},
 *   'userId': '',
 * }
 *
 * for type bank, the details value take this form:
 * {
 *   'accountName': '',
 *   'accountNumber': '',
 *   'accountCurrency': '',
 *   'bankCode': '',
 * }
 *
 * This comment is a reference for WIP, always reffer to the API documentation
 */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { Api, HTTP_STATUS } from '../../../shared';
import { bankAccountDetailsSchema } from '../../../shared/yupValidationRules';

const NAMESPACE = 'accountNumber';
const initialState = {
  status: HTTP_STATUS.IDLE,
  addUserPaymentMethodStatus: HTTP_STATUS.IDLE,
  message: null,
  bankAccounts: [],
};

/**
 * Add a new bank account
 */
export const addBankAccount = createAsyncThunk(`${NAMESPACE}/add`, async (bankAccountDetails) => {
  await bankAccountDetailsSchema.validate(bankAccountDetails);
  // make query
  const { data } = await Api.post('/user/payment-method', bankAccountDetails);
  return data;
});

/**
 * Get bank account numbers of the authenticated user
 */
export const getUserPaymentMethods = createAsyncThunk(`${NAMESPACE}/getAccountNumbers`, async () => {
  const { data } = await Api.get('/user/payment-methods');
  return data;
});

/**
 * Get all the bank accounts the authenticated user has in a bank
 *
 * Example
 * Get all the bank account the user has in WEMA Bank
 */
export const getUserAccountNumbersInABank = createAsyncThunk(
  `${NAMESPACE}/getAccountNumbersInBank`,
  async (bankCode) => {
    const { data } = await Api.get(`/user/bank-accounts?bankCode=${bankCode}`);
    return data;
  }
);

const accountNumberSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {
    baResetState(state, action) {
      state.message = '';
      state.status = HTTP_STATUS.IDLE;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addBankAccount.pending, (state, action) => {
        state.addUserPaymentMethodStatus = HTTP_STATUS.LOADING;
      })
      .addCase(addBankAccount.fulfilled, (state, action) => {
        const { status, description, _ } = action.payload;
        if (status.toLowerCase() === 'success') {
          state.addUserPaymentMethodStatus = HTTP_STATUS.DONE;
          state.message = 'Account number added successfully';
        } else {
          state.addUserPaymentMethodStatus = HTTP_STATUS.ERROR;
          state.message = description;
        }
      })
      .addCase(addBankAccount.rejected, (state, { error }) => {
        state.addUserPaymentMethodStatus = HTTP_STATUS.ERROR;
        state.message =
          error.code === 'ECONNABORTED'
            ? 'Connection timeout. Check your internet connection.'
            : error.message ?? 'SERVER ERROR';
      })
      .addCase(getUserPaymentMethods.pending, (state, action) => {
        state.status = HTTP_STATUS.LOADING;
      })
      .addCase(getUserPaymentMethods.fulfilled, (state, action) => {
        const { status, description, payload } = action.payload;
        if (status.toLowerCase() === 'success') {
          state.status = HTTP_STATUS.DONE;
          state.bankAccounts = payload;
        } else {
          state.status = HTTP_STATUS.ERROR;
          state.message = description;
        }
      })
      .addCase(getUserPaymentMethods.rejected, (state, { error }) => {
        state.status = HTTP_STATUS.ERROR;
        state.message =
          error.code === 'ECONNABORTED'
            ? 'Connection timeout. Check your internet connection.'
            : error.message ?? 'SERVER ERROR';
      })
      .addCase(getUserAccountNumbersInABank.pending, (state, action) => {
        state.status = HTTP_STATUS.LOADING;
      })
      .addCase(getUserAccountNumbersInABank.fulfilled, (state, action) => {
        const { status, description, payload } = action.payload;
        if (status.toLowerCase() === 'success') {
          state.status = HTTP_STATUS.DONE;
          state.bankAccounts = payload;
        } else {
          state.status = HTTP_STATUS.ERROR;
          state.message = description;
        }
      })
      .addCase(getUserAccountNumbersInABank.rejected, (state, { error }) => {
        state.status = HTTP_STATUS.ERROR;
        state.message =
          error.code === 'ECONNABORTED'
            ? 'Connection timeout. Check your internet connection.'
            : error.message ?? 'SERVER ERROR';
      });
  },
});

export const { baResetState } = accountNumberSlice.actions;

export default accountNumberSlice.reducer;
