import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { SETTINGS_PAGE } from '../utils';
import { Cache } from '../../../cache';

const NAMESPACE = 'settingsNav';
const CACHE_KEY = 'settingsPage';

export const changePage = createAsyncThunk(`${NAMESPACE}/set`, async (payload) => {
  const cache = new Cache();
  const cacheResponse = await cache.get(CACHE_KEY);
  if (cacheResponse === undefined || cache.isCachedExpired(cacheResponse.timeCached)) {
    return SETTINGS_PAGE.account;
  }
  return payload;
});

export const getSettingsPage = createAsyncThunk(`${NAMESPACE}/get`, async () => {
  const cache = new Cache();
  const cacheResponse = await cache.get(CACHE_KEY);

  if (cacheResponse === undefined || cache.isCachedExpired(cacheResponse.timeCached)) {
    return SETTINGS_PAGE.account;
  }
  return cacheResponse.payload;
});

const settingsNavigationSlice = createSlice({
  name: NAMESPACE,
  initialState: {
    page: '',
  },

  extraReducers: (builder) => {
    builder
      .addCase(changePage.fulfilled, (state, action) => {
        new Cache().put(CACHE_KEY, action.payload);
        state.page = action.payload;
      })
      .addCase(getSettingsPage.fulfilled, (state, action) => {
        state.page = action.payload;
      });
  },
});

// export const { changePage } = settingsNavigationSlice.actions;

export default settingsNavigationSlice.reducer;
