import { extendTheme } from '@chakra-ui/react';
import fonts from './fonts';
import colors from './colors';
import button from './button';

const theme = extendTheme({
  colors,
  fonts,
  components: {
    Button: button,
  },
});

export default theme;
