import * as yup from 'yup';

export const paymentMethodSchema = yup.object().shape({
  type: yup.string().required(),
  code: yup.string().required(),
  name: yup.string().required(),
  id: yup.string(),
  details: yup.object(),
});

export const bankAccountDetailsSchema = yup.object().shape({
  type: yup.string().required(),
  code: yup.string().required(),
  name: yup.string().required(),
  id: yup.string(),
  details: yup.object().shape({
    bankCode: yup.string().required(),
    country: yup.string().required(),
    accountCurrency: yup.string().required(),
    accountName: yup.string().required(),
    accountNumber: yup.string().required(),
  }),
});

export const offerDetailsSchema = yup.object().shape({
  rate: yup.number().positive().required(),
  offerType: yup.string().required(),
  assetSymbol: yup.string().required(),
  status: yup.string().required(),
  amount: yup.number().positive().required(),
  paymentMethod: yup.string().required(),
  paymentDetailsId: yup.string(),
  priceType: yup.string().required(),
  minimumTradeAmount: yup.number().positive().required(),
  timeLimit: yup.number().positive().required(),
  tradeInstruction: yup.string(),
  country: yup.string().required(),
  paymentDetails: yup.object(),
});
