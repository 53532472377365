import numeral from 'numeral';
import { BigNumber } from 'bignumber.js';

function capitalizeFirstLetter(char) {
  if (typeof char !== 'string' || char === '') return char;
  return char[0].toUpperCase() + char.slice(1).toLowerCase();
}

function titleCase(char) {
  if (typeof char !== 'string') return char;
  return char
    .split(' ')
    .map((x) => capitalizeFirstLetter(x))
    .join(' ');
}

function formatMoney(amount) {
  return numeral(amount).format('0,0.00');
}

function formatCryptoAsset(amount) {
  return numeral(amount).format('0,0.00000');
}
function isEmail(email) {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
}

/**
 * Covert a crypto amount to fait
 * Example - 0.25 BTC to NGN
 * @param {Number} marketPrice Crypto market price in USD
 * @param {Number} rate Crypto conversion rate in local currency
 * @param {Number} amount - Amount in crypto (example: 0.25 BTC)
 * @returns Number representing the amount in local currency (fait)
 */
function cryptoToFait(marketPrice, rate, amount) {
  const a = amount !== '' ? BigNumber(amount) : BigNumber(0);
  const r = rate !== '' ? BigNumber(rate) : BigNumber(0);
  const fait = a.multipliedBy(marketPrice).multipliedBy(r).decimalPlaces(2);

  return fait.toNumber();
}
export { capitalizeFirstLetter, titleCase, formatMoney, formatCryptoAsset, isEmail, cryptoToFait };
