const firstComments = [
  {
    name: 'Sage Adebayo',
    src: 'https://bit.ly/sage-adebayo',
    comment: `Lorem ipsum dolor sit amet consectetur, adipisicing elit.Qui reprehenderit,
veniam voluptatum voluptate non iste distinctio voluptas quis ab magnam quaerat eos`,
    isOpen: false,
  },
  {
    name: 'Dan Abramov',
    src: 'https://bit.ly/dan-abramov',
    comment: `Lorem ipsum dolor sit amet consectetur, adipisicing elit.Qui reprehenderit,
veniam voluptatum voluptate non iste distinctio voluptas quis ab magnam quaerat eos`,
    isOpen: false,
  },
  {
    name: 'James Rusel',
    src: 'https://randomuser.me/api/portraits/men/97.jpg',
    comment: `Lorem ipsum dolor sit amet consectetur, adipisicing elit.Qui reprehenderit,
veniam voluptatum voluptate non iste distinctio voluptas quis ab magnam quaerat eos`,
    isOpen: false,
  },
];

const secondComments = [
  {
    name: 'Miriam Dane',
    src: 'https://randomuser.me/api/portraits/women/95.jpg',
    comment: `Lorem ipsum dolor sit amet consectetur, adipisicing elit.Qui reprehenderit,
veniam voluptatum voluptate non iste distinctio voluptas quis ab magnam quaerat eos`,
    isOpen: false,
  },
  {
    name: 'Obinna Okafor',
    src: 'https://randomuser.me/api/portraits/men/91.jpg',
    comment: `Lorem ipsum dolor sit amet consectetur, adipisicing elit.Qui reprehenderit,
veniam voluptatum voluptate non iste distinctio voluptas quis ab magnam quaerat eos`,
    isOpen: false,
  },
  {
    name: 'Mia Deis',
    src: 'https://randomuser.me/api/portraits/women/2.jpg',
    comment: `Lorem ipsum dolor sit amet consectetur, adipisicing elit.Qui reprehenderit,
veniam voluptatum voluptate non iste distinctio voluptas quis ab magnam quaerat eos`,
    isOpen: false,
  },
  {
    name: 'Mia eis',
    src: 'https://randomuser.me/api/portraits/women/2.jpg',
    comment: `Lorem ipsum dolor sit amet consectetur, adipisicing elit.Qui reprehenderit,
veniam voluptatum voluptate non iste distinctio voluptas quis ab magnam quaerat eos`,
    isOpen: false,
  },
];

export { firstComments, secondComments };
