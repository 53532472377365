import { configureStore } from '@reduxjs/toolkit';

import sideNavReducer from './slices/sideNavigationSlice';
import authReducer from '../modules/auth/slices/authSlice';
import settingsNavigationReducer from '../modules/settings/slices/settingsNavSlice';
import userReducer from './slices/userSlice';
import notificationReducer from '../modules/settings/slices/notificationSlice';

import countryReducer from './slices/countrySlice';
import createOfferReducer from '../modules/createOffer/slices/createOfferSlice';
import paymentMethodsReducer from '../modules/createOffer/slices/paymentMethodSlice';
import bankReducer from './slices/bankSlice';
import cryptoAssetSlice from './slices/cryptoAssetsSlice';
import tickersReducer from './slices/tickerSlice';
import bankAccountReducer from '../modules/settings/slices/accountNumberSlice';
import offerReducer from '../modules/createOffer/slices/offerSlice';
import TradeReducer from '../modules/trade/slices/tradeSlice';
import phoneNumberReducer from './slices/phoneNumberSlice';
import getUserOfferReducer from '../modules/advertiser/slices/getOfferSlice';
import getTradeHistoryReducer from '../modules/tradeHistory/slices/getTradeHistorySlice';
import marketCoinReducer from './slices/marketCoinslice';
import offerSummaryReducer from '../modules/trade/slices/offerSummarySlice';
import submitReviewReducer from '../modules/payment/slices/giveFeedBack';
import joinWaitingListReducer from '../modules/landingPage/slices/joinWaitingListSlice';
import getWalletReducer from '../modules/wallet/slices/getWalletSlice';
import blockchainNetworkReducer from '../modules/wallet/slices/networkSlice';
import getExchangeRateReducer from './slices/exchangeRate';
import feedbackReducer from './slices/feedbacks';
import offerActionsReducer from './slices/offers/offerActionSlice';
import dashboardTabReducer from '../modules/dashboard/slices/tabSlice';
import getOffersReducer from './slices/offers/getOffersSlice';
import getBalanceReducer from '../modules/wallet/slices/getBalanceSlice';

const store = configureStore({
  reducer: {
    toggleSideNavigation: sideNavReducer,
    auth: authReducer,
    settingsNavigation: settingsNavigationReducer,
    country: countryReducer,
    user: userReducer,
    notificationSettings: notificationReducer,
    createOffer: createOfferReducer,
    offer: offerReducer,
    paymentMethod: paymentMethodsReducer,
    bank: bankReducer,
    cryptoAsset: cryptoAssetSlice,
    tickers: tickersReducer,
    bankAccount: bankAccountReducer,
    getUserOffer: getUserOfferReducer,
    trade: TradeReducer,
    offerSummary: offerSummaryReducer,
    phoneNumber: phoneNumberReducer,
    marketCoin: marketCoinReducer,
    tradeHistory: getTradeHistoryReducer,
    submitReview: submitReviewReducer,
    waitingList: joinWaitingListReducer,
    getWallet: getWalletReducer,
    blockchainNetwork: blockchainNetworkReducer,
    exchangeRate: getExchangeRateReducer,
    feedback: feedbackReducer,
    offerActions: offerActionsReducer,
    dashboardTab: dashboardTabReducer,
    getOffers: getOffersReducer,
    getBalance: getBalanceReducer,
  },
});

export default store;
