import { idb } from './indexdb';

class Cache {
  constructor() {
    this.dbName = 'coinkudiCache';
    this.version = 1;
    this.storeName = 'caches';
  }

  async initilize() {
    const db = await idb(this.dbName, this.version);
    const transaction = db.transaction(this.storeName, 'readwrite');

    return { transaction, db };
  }

  async put(cacheName, cacheObj) {
    // does the same thing as add and update
    const init = await this.initilize();
    await init.transaction.objectStore(this.storeName).put({ payload: cacheObj, timeCached: Date.now() }, cacheName);
    return true;
  }

  async get(cacheName) {
    try {
      const init = await this.initilize();
      const result = await init.transaction.objectStore(this.storeName).get(cacheName);

      return result;
    } catch (error) {
      console.log(error, 'error');
    }
  }

  async delete(cacheName) {
    const init = await this.initilize();
    const r = await init.transaction.objectStore(this.storeName).delete(cacheName);

    console.log(r, '-- delete result --');

    return true;
  }

  /**
   * Check the that the cache is not stale
   * by computing the difference between the current time
   * and the time the cache was created
   *
   * @param {Number} timeCached - Time when the cache was saved.
   * @param {Number} ttlSeconds - The time to live in seconds
   * @returns boolean - true if cache has expired, false otherwise
   */
  isCachedExpired(timeCached, ttlSeconds = 48 * 60 * 60) {
    if (timeCached === undefined) return true;
    const now = Date.now();
    const diff = Math.floor((now - timeCached) / 1000);

    return diff > ttlSeconds;
  }
}

export default Cache;
