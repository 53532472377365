import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { Api, HTTP_STATUS } from '../../shared';
import { Cache } from '../../cache';

const NAMESPACE = 'feedback';

const initialState = {
  feedbacks: [],
  getFeedbacksStatus: HTTP_STATUS.IDLE,
  getFeedbacksMessage: null,
  feedbackCount: {},
  countFeedbackStatus: HTTP_STATUS.IDLE,
  countFeedbackMessage: null,
  submitFeebackStatus: HTTP_STATUS.IDLE,
  submitFeebackMessage: null,
};

export const getUserFeedBacks = createAsyncThunk(`${NAMESPACE}/fetchData`, async (userId) => {
  // userID in the URL indicate that the authenticated user is checking another user profile
  // in the case wherre there is no UserID in the URL the feeedback for the current auth user is fetched
  const query = [null, '', undefined].includes(userId) ? {} : { to: userId };
  const { data } = await Api.get(`/user/feedbacks`, {
    params: {
      ...query,
    },
  });
  return data.payload;
});

export const countUserFeedBacks = createAsyncThunk(`${NAMESPACE}/count`, async (userId) => {
  const query = [null, '', undefined].includes(userId) ? {} : { to: userId };
  const { data } = await Api.get(`/user/feedback/count`, {
    params: {
      ...query,
    },
  });
  return data.payload;
});

export const giveFeedBack = createAsyncThunk(`${NAMESPACE}/submit`, async (currency) => {
  const { data } = await Api.post(`/user/feedback`, {
    for: '',
    to: '',
    type: '',
    review: '',
  });
  return data.payload;
});

const feedbackSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getUserFeedBacks.pending, (state, action) => {
        state.getFeedbacksStatus = HTTP_STATUS.LOADING;
      })
      .addCase(getUserFeedBacks.fulfilled, (state, action) => {
        const p = action.payload;
        state.feedbackCount = p.count ?? {};
        state.feedbacks = p.feedbacks ?? [];
        state.getFeedbacksStatus = HTTP_STATUS.DONE;
      })
      .addCase(getUserFeedBacks.rejected, (state, { error }) => {
        state.getFeedbacksStatus = HTTP_STATUS.ERROR;
        state.getFeedbacksMessage = !error.status
          ? 'Error with your network connection'
          : error.message ?? 'SERVER ERROR';
      })
      .addCase(countUserFeedBacks.pending, (state, action) => {
        state.countFeedbackStatus = HTTP_STATUS.LOADING;
      })
      .addCase(countUserFeedBacks.fulfilled, (state, action) => {
        state.feedbackCount = action.payload;
        state.countFeedbackStatus = HTTP_STATUS.DONE;
      })
      .addCase(countUserFeedBacks.rejected, (state, { error }) => {
        state.countFeedbackStatus = HTTP_STATUS.ERROR;
        state.countFeedbackMessage = !error.status
          ? 'Error with your network connection'
          : error.message ?? 'SERVER ERROR';
      })
      .addCase(giveFeedBack.pending, (state, action) => {
        state.submitFeebackStatus = HTTP_STATUS.LOADING;
      })
      .addCase(giveFeedBack.fulfilled, (state, action) => {
        state.submitFeebackStatus = HTTP_STATUS.DONE;
      })
      .addCase(giveFeedBack.rejected, (state, { error }) => {
        state.submitFeebackStatus = HTTP_STATUS.ERROR;
        state.submitFeebackMessage = !error.status
          ? 'Error with your network connection'
          : error.message ?? 'SERVER ERROR';
      });
  },
});

export default feedbackSlice.reducer;
