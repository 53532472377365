import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { Api, HTTP_STATUS } from '../../shared';
import { Cache } from '../../cache';

const NAMESPACE = 'exchangeRate';

const initialState = {
  exchangeRate: {},
  exchangeRateStatus: HTTP_STATUS.IDLE,
  exchangeRateMessage: null,
};

export const getExchangeRate = createAsyncThunk(`${NAMESPACE}/fetchData`, async (currency) => {
  const cache = new Cache();
  const cacheResponse = await cache.get(`usd${currency}`.toLowerCase());
  // expires after an hour
  const ttl = 60 * 60;

  if (cacheResponse === undefined || cache.isCachedExpired(cacheResponse.timeCached, ttl)) {
    const { data } = await Api.get(`/exchange_rate?currency=${currency}`);
    return data.payload;
  }

  return cacheResponse.payload;
});

const exchangeRateSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getExchangeRate.pending, (state, action) => {
        state.exchangeRateStatus = HTTP_STATUS.LOADING;
      })
      .addCase(getExchangeRate.fulfilled, (state, action) => {
        const cacheName = `${action.payload.currencyPairs}`.toLowerCase();
        new Cache().put(cacheName, action.payload);
        state.exchangeRate = action.payload;
        state.exchangeRateStatus = HTTP_STATUS.DONE;
      })
      .addCase(getExchangeRate.rejected, (state, { error }) => {
        state.exchangeRateStatus = HTTP_STATUS.ERROR;
        state.exchangeRateMessage = !error.status
          ? 'Error with your network connection'
          : error.message ?? 'SERVER ERROR';
      });
  },
});

export default exchangeRateSlice.reducer;
