import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { Api, HTTP_STATUS } from '../../../shared';

const NAMESPACE = 'getTrade';

const initialState = {
  tradeLoadingStatus: HTTP_STATUS.IDLE,
  trades: [],
  message: '',
};

export const getUserTradeHistory = createAsyncThunk(`${NAMESPACE}/history`, async (status) => {
  const { data } = await Api.get('/trades', {
    params: {
      status: status,
      assetSymbol: 'btc',
    },
  });
  return data.payload;
});

const getUserlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getUserTradeHistory.pending, (state, action) => {
        state.tradeLoadingStatus = HTTP_STATUS.LOADING;
      })
      .addCase(getUserTradeHistory.rejected, (state, { error }) => {
        console.log({ error });
        state.tradeLoadingStatus = HTTP_STATUS.ERROR;
        state.message = !error.status ? 'Error with your network connection' : error.message ?? 'SERVER ERROR';
      })
      .addCase(getUserTradeHistory.fulfilled, (state, action) => {
        state.trades = action.payload?.trades || [];
        state.tradeLoadingStatus = HTTP_STATUS.DONE;
      });
  },
});

// export const { setPaymentMethodSelection } = getUserlice.actions;

export default getUserlice.reducer;
