import Axios from 'axios';
import { getAuthToken } from './constant';

function _endpoint() {
  const env = process.env.REACT_APP_ENVIRONMENT;
  if (['production', 'prod'].includes(env)) {
    return 'https://coinkudi-gateway-a3syisy3.nw.gateway.dev';
  } else if ('staging' === env) {
    return 'https://staging-gateway-a3syisy3.nw.gateway.dev';
  } else if (['fs', 'fullstack'].includes(env)) {
    return 'http://127.0.0.1:20011';
  } else {
    // dev environment - use staging endpoint
    return 'https://staging-gateway-a3syisy3.nw.gateway.dev';
  }
}
// get user JWT token
const j = getAuthToken();
const { t: _token, i: _uid } = j ?? { t: 'NO_TOKEN', i: 'NO_UID' };

const AxiosInstance = Axios.create({
  baseURL: _endpoint(),
  headers: {
    'Content-Type': 'application/json',
    'x-requesting-service': 'coinkudi',
    'x-uid': _uid,
    // user token
    'x-token': `${_token}`,
    // api gateway authentication
    // Authorization: `Bearer ${_token}`,
  },
});

AxiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    console.log(error, { ...error }, 'error');
    // network error don't have the response property
    if (error.response) {
      const { status, data } = error.response;
      if (data && status > 201) {
        return Promise.reject(new Error(data.description));
      }
      if ([undefined, null, '', false].includes(data) && status === 500) {
        return Promise.reject(new Error('Internal server error'));
      }
      // return Promise.reject(new Error(error.response));
      return error.response;
    }
    return Promise.reject(error);
  }
);

export default AxiosInstance;
