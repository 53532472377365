import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { Api, HTTP_STATUS, AUTHENTICATION_STATUS, deleteAuthToken } from '../../../shared';
import * as yup from 'yup';

const namespace = 'authentication';
const initialState = {
  status: HTTP_STATUS.IDLE,
  userId: null,
  token: null,
  message: null,
  authenticated: AUTHENTICATION_STATUS.UNKNOWN,
};
/**
 * Handles both sign-in and sign-up request
 */

export const authRequest = createAsyncThunk(`${namespace}/request`, async (authData, thunkAPI) => {
  // parameter schema
  const authDetailsSchema = yup.object().shape({
    IPAddress: yup.string(),
    deviceName: yup.string().required(),
    operatingSystem: yup.string().required(),
    email: yup.string().email().required(),
    country: yup.string(),
  });

  const isValid = authDetailsSchema.isValidSync(authData);
  if (!isValid) {
    return { status: 'INVALID_INPUT', description: 'Invalid data passed.' };
  } else {
    // make query
    const { data } = await Api.post('/passwordless/email/request', authData);
    return data;
  }
});

/**
 * Validate/authorize passwordless authentication
 */

const authDetailsSchema = yup.object().shape({
  otp: yup.string().required(),
  uid: yup.string().required(),
  aud: yup.string().required(),
});

export const authorizeAuthentication = createAsyncThunk(`${namespace}/authorization`, async (authData) => {
  const isValid = authDetailsSchema.isValidSync(authData);
  if (!isValid) {
    // url has been tempered with
    return { status: 'INVALID_URL_DATA', description: 'Invalid authentication data. Try logn/sign-up again.' };
  } else {
    // make query
    const { data } = await Api.post('/passwordless/email/auth', authData);
    return data;
  }
});

/**
 * Validate and verify JWT token
 */
export const verifyJWT = createAsyncThunk(`${namespace}/verifyJWT`, async () => {
  const { data } = await Api.get('/verify-jwt');

  return data;
});

const authSlice = createSlice({
  name: namespace,
  initialState,
  reducers: {
    unAuthenticate(state, action) {
      deleteAuthToken();
      state.authenticated = AUTHENTICATION_STATUS.UN_AUTHENTICATED;
      state.userId = null;
      state.token = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(authRequest.pending, (state, action) => {
        state.status = HTTP_STATUS.LOADING;
      })
      .addCase(authRequest.fulfilled, (state, action) => {
        const { status, description, _ } = action.payload;
        if (status.toLowerCase() === 'success') {
          state.status = HTTP_STATUS.DONE;
          window.location.href = '/auth/proceed';
        } else {
          state.status = HTTP_STATUS.ERROR;
          state.message = description;
        }
      })
      .addCase(authRequest.rejected, (state, { error }) => {
        state.status = HTTP_STATUS.ERROR;
        state.message =
          error.code === 'ECONNABORTED'
            ? 'Connection timeout. Check your internet connection.'
            : error.message ?? 'SERVER ERROR';
      })
      .addCase(authorizeAuthentication.pending, (state, action) => {
        state.status = HTTP_STATUS.LOADING;
      })
      .addCase(authorizeAuthentication.fulfilled, (state, action) => {
        const { status, description, payload } = action.payload;
        if (status.toLowerCase() === 'success') {
          state.status = HTTP_STATUS.DONE;
          state.userId = payload.userId;
          state.token = payload.token;
          state.authenticated = AUTHENTICATION_STATUS.AUTHENTICATED;
        } else {
          state.status = HTTP_STATUS.ERROR;
          state.authenticated = AUTHENTICATION_STATUS.UNKNOWN;
          state.message = description;
        }
      })
      .addCase(authorizeAuthentication.rejected, (state, { error }) => {
        state.status = HTTP_STATUS.ERROR;
        state.message =
          error.code === 'ECONNABORTED'
            ? 'Connection timeout. Check your internet connection.'
            : error.message ?? 'SERVER ERROR';
      })
      .addCase(verifyJWT.pending, (state, action) => {
        state.status = HTTP_STATUS.LOADING;
        state.authenticated = AUTHENTICATION_STATUS.VERIFYING;
      })
      .addCase(verifyJWT.fulfilled, (state, action) => {
        const { status, description, payload } = action.payload;
        if (status.toLowerCase() === 'success') {
          state.status = HTTP_STATUS.DONE;
          state.authenticated = AUTHENTICATION_STATUS.AUTHENTICATED;
          state.userId = payload.userId;
          state.token = payload.token;
        } else {
          deleteAuthToken();
          state.status = HTTP_STATUS.ERROR;
          state.authenticated = AUTHENTICATION_STATUS.UNKNOWN;
          state.message = description;
          state.authenticated = false;
        }
      })
      .addCase(verifyJWT.rejected, (state, { error }) => {
        state.status = HTTP_STATUS.ERROR;
        state.authenticated = AUTHENTICATION_STATUS.UNKNOWN;
        state.message =
          error.code === 'ECONNABORTED'
            ? 'Connection timeout. Check your internet connection.'
            : error.message ?? 'SERVER ERROR';
        state.authenticated = false;
      });
  },
});

export const { unAuthenticate } = authSlice.actions;

export default authSlice.reducer;
