import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { Api, HTTP_STATUS } from '../../../shared';
import { Cache } from '../../../cache';

const NAMESPACE = 'getOffers';

const initialState = {
  waitingStatus: HTTP_STATUS.IDLE,
  message: '',
  toastStatus: 'success',
  toastTitle: '',
};

export const joinWaitList = createAsyncThunk(`${NAMESPACE}`, async (email) => {
  const { data } = await Api.post('/waitlist', {
    email: email,
  });
  return data;
});

const joinWaitListSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(joinWaitList.pending, (state, action) => {
        state.waitingStatus = HTTP_STATUS.LOADING;
      })
      .addCase(joinWaitList.rejected, (state, { error }) => {
        state.waitingStatus = HTTP_STATUS.ERROR;
        state.toastStatus = 'error';
        state.toastTitle = 'Error';
        state.message = !error.status ? 'Error with your network connection' : error.message ?? 'SERVER ERROR';
      })
      .addCase(joinWaitList.fulfilled, (state, action) => {
        state.waitingStatus = HTTP_STATUS.DONE;
        if (action.payload?.status == 'SUCCESS') {
          state.toastStatus = 'success';
          state.toastTitle = 'Email Saved';
          state.message =
            'Your email address has been added to the waiting list, You will get notified as soon as we launch';
        } else {
          state.toastStatus = 'error';
          state.toastTitle = 'Error';
          state.message = action.payload?.description;
        }
      });
  },
});

export default joinWaitListSlice.reducer;
